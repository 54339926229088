@font-face {
    font-family: 'Yekan-en';
    src: url('../../../Fonts/YEKANBAKH-FAT.TTF');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan-en';
    src: url('../../../Fonts/YEKANBAKH-HEAVY.TTF');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan-en';
    src: url('../../../Fonts/YEKANBAKH-BOLD.TTF');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan-en';
    src: url('../../../Fonts/YEKANBAKH-MEDIUM.TTF');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan-en';
    src: url('../../../Fonts/YEKANBAKH-REGULAR.TTF');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan-en';
    src: url('../../../Fonts/YEKANBAKH-LIGHT.TTF');
    font-weight: 100;
    font-style: normal;
}

/*fa*/
@font-face {
    font-family: 'Yekan';
    src: url("../../../Fonts/YEKANBAKH-LIGHT-fa.woff2") format("woff2"),
    url("../../../Fonts/YEKANBAKH-LIGHT-fa.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan';
    src: url("../../../Fonts/YEKANBAKH-REGULAR-fa.woff2") format("woff2"),
    url("../../../Fonts/YEKANBAKH-REGULAR-fa.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan';
    src: url("../../../Fonts/YEKANBAKH-MEDIUM-fa.woff2") format("woff2"),
    url("../../../Fonts/YEKANBAKH-MEDIUM-fa.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan';
    src: url("../../../Fonts/YEKANBAKH-BOLD-fa.woff2") format("woff2"),
    url("../../../Fonts/YEKANBAKH-BOLD-fa.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan';
    src: url("../../../Fonts/YEKANBAKH-HEAVY-fa.woff2") format("woff2"),
    url("../../../Fonts/YEKANBAKH-HEAVY-fa.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Yekan';
    src: url("../../../Fonts/YEKANBAKH-FAT-fa.woff2") format("woff2"),
    url("../../../Fonts/YEKANBAKH-FAT-fa.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}
